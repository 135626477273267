* {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  box-sizing: border-box !important;
  scroll-behavior: smooth !important;
  list-style: none;
  text-decoration: none;
  overscroll-behavior: none !important;
}

:root {
  --primary: rgba(rgb(5, 5, 5, 1));
  --light: rgba(241, 241, 241, 0.822);
}
