#gallery .container img {
  width: 200px;
  height: 200px;
}

#gallery .container .grid {
  display: grid;
  grid-template-columns: repeat(3, 200px);
  justify-content: center;
  align-content: center;
  grid-gap: 10px;
  height: 100vh;
}

#gallery .container .grid img {
  width: 200px;
  height: 200px;
  cursor: pointer;
}

#lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
}

#lightbox.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

#lightbox img {
  max-width: 90%;
  max-height: 80%;
  padding: 4px;
  background-color: black;
  border: 2px solid white;
}

#GalleryCard {
  width: 100%;
}

#GalleryCard .slideshow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

._3q7r8 {
  background-color: rgba(30, 30, 30, 0) !important;
  flex-direction: column-reverse !important;
}
._t1897 {
  background-color: rgba(5, 5, 5, 0.527) !important;
  filter: brightness(0.8);
}

.__JnHV {
  background-color: transparent !important;
  color: var(--light);
  opacity: 0.3 !important;
}
._29p_Y {
  bottom: 0 !important;
  right: 0 !important;
  top: unset !important;
  left: unset !important;
  margin: unset !important;
}

._2c50p {
  margin: 1rem 0 !important;
}

#GalleryCard .bx-chevron-left,
#GalleryCard .bx-chevron-right {
  font-size: 5rem;
  color: var(--light);
}

#GalleryCard .bx-expand-alt {
  font-size: 2rem;
  color: var(--primary);
}

#GalleryCard .bx-x {
  font-size: 5rem;
  color: red;
  padding-top: 1rem;
  padding-right: 0.1rem;
}

#GalleryCard .bx-expand-alt {
  position: absolute;
  right: 0;
  display: none;
  padding: 1rem;
}

._t1897 {
  object-fit: contain !important;
}

@media only screen and (max-width: 1600px) {
  ._2ILZE {
    height: 500px !important;
  }
}
