.event-card-wrap {
  background-color: rgb(5, 5, 5);
  padding: 0.5rem 2rem;
  margin: 1rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  color: var(--light);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border: 0.25px solid rgb(20, 20, 20);
}

.event-card-image {
  width: 6%;
  background: url(../../../public/images/logo2.svg) no-repeat center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  filter: invert(1);
  margin: 0.5rem;
}

.event-card-image:hover {
  transform: scale(1.05);
}

.event-card-wrap:hover {
  transform: scale(1.03);
}

.event-details h1 {
  margin: 0 0 5px;
  font-size: 1.2em;
  color: var(--light);
  text-transform: unset !important;
}

.event-details h3 {
  font-size: 0.8em;
}
.event-details p {
  font-size: 0.6sem;
}

.event-details h3,
.event-details p {
  margin: 10px 0;
  color: var(--light);
}

.event-details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .event-card-wrap {
    text-align: center;
    gap: 0;
    border: 1px solid #ffffff34;
    display: flex;
    flex-direction: row-reverse;
    padding: 0.5rem 1.25rem !important;
    border-radius: unset;
  }

  .event-details {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }

  .event-details h1 {
    font-size: 0.8em !important;
  }

  .event-details .event_width {
    width: 90%;
  }

  .event_flexStart {
    flex-direction: column !important;
    text-align: start !important;
    display: flex !important;
    align-items: flex-start !important;
  }

  .event-details h3 {
    font-size: 1em !important;
  }

  .event-card-image {
    margin: 0 auto 20px;
  }
}

.selected-event {
  border: 6px solid red; /* Červený border pro vybranou kartu */
}

.section_adduser .buttons {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.section_adduser .buttons button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid black;
  background-color: #fff;
  margin-right: 0.5rem;
  cursor: pointer;
}

.event-cards-container {
  max-height: 550px; /* Nastavte výšku podle potřeby */
  overflow-y: auto; /* Přidá posuvník, pokud je obsah příliš vysoký */
}
