.center {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

img {
  height: auto;
  width: 100%;
}

section {
  min-height: 100%;
}

#VideoContainer {
  position: fixed !important;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

#Video {
  position: relative;
  width: 110%; /* scale video size */
  height: 110%; /* scale video size */
  top: -5%; /* center the scaled video */
  left: -5%; /* center the scaled video */
  filter: brightness(0.2) blur(1px);
  object-fit: cover;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .animated {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 0.2rem;
}

::-webkit-scrollbar-track {
  background: rgb(44, 44, 44);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.233);
}

/* SCROLLBAR */

/* first page */

@media only screen and (max-height: 768px) {
  .first-page .padding-mp {
    padding-top: 3rem !important;
  }
}

.first-page .logo-main img {
  width: 25%;
  height: auto;
  filter: invert(1);
}

.first-page .soc_ico i {
  color: var(--light);
  font-size: 1.5rem;
  transition: 0.2s ease-in-out;
  border: 1px solid var(--light);
  border-radius: 50%;
  padding: 0.5rem;
}

.first-page .soc_ico i:hover {
  scale: 1.2;
}

.first-page .soc_ico .bx {
  margin-bottom: 1rem;
}

.first-page h3,
.first-page h1 {
  color: var(--light);
  letter-spacing: 1px;
  padding: 2rem 8rem 4rem 8rem;
  font-size: 1.1rem;
  font-weight: 300;
}

@media only screen and (max-width: 768px) {
  .first-page h3,
  .first-page h1 {
    padding: 1rem;
    font-size: 0.9rem;
  }
  .first-page .soc_ico {
    margin-top: 5rem;
    margin-right: unset !important;
  }

  .first-page .soc_ico a {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .first-page .soc_ico i {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .first-page .soc_ico i {
    font-size: 1.5rem;
  }
}

.first-page .scroll-arrow i {
  color: var(--light);
  font-size: 4rem;
  animation: scrollFade 1.5s infinite;
  opacity: 0.8;
}

@keyframes scrollFade {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}

.scroll-arrow {
  margin-top: 2rem;
}

/* Nav page */

.nav-page,
.memberPage,
.ourProduction_page,
.eventPage,
.gallery-page,
.repertoarPage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #080808;
  background: -webkit-linear-gradient(195deg, #080808 0%, #0f0f0f 100%);
  background: linear-gradient(195deg, #080808 0%, #0f0f0f 100%);
}

.ourProduction_page h1,
.memberPage h1,
.eventPage h1 {
  text-transform: uppercase;
  color: var(--light);
  text-align: left;
  font-size: 2.5em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding-bottom: 6px;
}

.memberPage h1 {
  font-size: 1.5rem !important;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .memberPage h1 {
    font-size: 1.5rem !important;
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .memberPage h1 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .card-wrap {
    padding-bottom: 1rem !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    margin: 0.5rem 0;
  }

  .card-wrap h3 {
    font-size: 0.8rem !important;
  }

  .card-wrap p {
    font-size: 0.6rem !important;
  }
}

.memberPage {
  background-color: rgb(27, 27, 27) !important;
}

/* Kontaktní sekce */

.contact {
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20% !important;
}

.contact h1 {
  color: var(--light);
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-info p {
  color: var(--light);
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1rem;
  padding-right: 3rem;
}

.contact-info a {
  color: var(--light);
  font-size: 1.1rem;
  margin-left: 0.25rem;
}
.social-icons {
  margin-top: 2rem;
}

.social-icons a {
  margin: 0 1rem;
}

.social-icons i {
  font-size: 2rem;
  color: var(--light);
  transition: transform 0.3s ease-in-out;
}

.social-icons i:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 768px) {
  .contact {
    padding: 3rem 0;
  }

  .contact .center {
    flex-direction: column;
  }

  .contact-info p {
    font-size: 0.8rem;
    padding: 1rem !important;
  }
  .social-icons i {
    font-size: 1.5rem;
  }
  .contact h1 {
    font-size: 1em;
  }
}

/* paralax image */

#paralax-image img {
  height: 20vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#paralax-image .paralax {
  background-image: linear-gradient(rgba(0, 0, 0, 0.795), rgba(0, 0, 0, 0.76)), url("../../../public/images/band.jpeg");
  background-size: cover;
  background-attachment: fixed;
  height: 28vh;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#paralax-image .paralax h3 {
  color: rgb(223, 223, 223);
  font-weight: 600;
  font-size: 4.5rem;
  text-transform: uppercase;
  padding: 0 5rem;
}

@media (max-width: 874px) {
  #paralax-image .paralax h3 {
    font-size: 30px;
  }
}

@media (max-width: 1200px) {
  #paralax-image .paralax {
    background-attachment: unset !important;
  }
}

@media (max-width: 548px) {
  #paralax-image .paralax h3 {
    font-size: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
}

.styledHr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
  margin-bottom: 2rem;
  filter: opacity(0.2);
}
