.card-wrap .card {
  width: 100%;
  max-width: 200px;
  height: auto;
  min-height: 250px;
  background-image: linear-gradient(163deg, #fcfcfc67 0%, #69686e 100%);
  border-radius: 4px;
  transition: all 0.3s;
  overflow: hidden;
}

.card-wrap .card2 {
  width: 100%;
  max-width: 200px;
  height: auto;
  min-height: 250px;
  background-position: center;
  background-size: cover;
  transition: all 0.2s;
  border-radius: 4px;
  filter: brightness(0.6);
}

.card-wrap .card2:hover {
  transform: scale(1.2);
  filter: brightness(1);
}

.card-wrap:hover {
  transform: scale(1.05);
}

.card-wrap .card:hover {
  box-shadow: 0px 0px 30px 1px rgba(190, 190, 190, 0.3);
}

.card-wrap {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  margin: 1.5rem 0;
}

.card-wrap h3 {
  color: var(--light);
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
}
.card-wrap p {
  margin: 0;
  padding: 0;
  color: var(--light);
  font-size: 0.8rem;
}

.selected-event {
  border: 2px solid red; /* Červený border pro vybranou kartu */
}
