/* songTable.css */

.song-table-container {
  max-height: 800px;
  overflow: auto;
  border-radius: 0.25rem;
  margin: 0 auto;
  padding: 10px;
  width: 60%;
  margin-top: 4rem;
  position: relative;
  background-color: rgb(5, 5, 5);
}

@media (max-width: 1600px) {
  .song-table-container {
    max-height: 550px !important;
  }
  
}

.song-table {
  width: 100%;
  border-collapse: collapse;
}

/* Přidáno pro záhlaví tabulky */
.song-table thead th {
  background-color: #47474728;
}

.song-table th {
  border-bottom: 1px solid #8884841c;
  height: 1px;
  margin-bottom: 2rem;
  font-weight: bold;
}

.song-table th,
.song-table td {
  padding: 15px;
  text-align: left;
  color: var(--light);
}

.song-table tr:hover {
  background-color: rgb(7, 7, 7);
}

.search-input {
  width: 100%;
  padding: 20px 15px;
  border-radius: 5px;
  font-size: 16px;
  color: var(--light);
  background-color: transparent;
  border: none;
  outline: none;
}

.search-input::placeholder {
  color: #999;
}

.song-table .song {
  display: flex;
  align-items: center;
}

.song-table .song img {
  width: 6%;
  height: 100%;
  margin-right: 1rem;
  border-radius: 0.5rem;
}

@media (max-width: 1202px) {
  .song-table .song img {
    display: none;
  }
  .song-table-container {
    width: 85%;
  }
}

.song-table button {
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: rgb(7, 7, 7);
  color: var(--light);
  border-radius: 0.25rem;
}

.song-table button a {
  color: var(--light);
}

.css-i6dzq1 {
  margin-right: 0.75rem;
}

@media only screen and (max-width: 600px) {
  .song-table button a {
    display: none;
  }
  .css-i6dzq1 {
    margin-right: 0;
  }
}
