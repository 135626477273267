.aparatura-slider .splide__arrow svg {
  fill: rgba(255, 255, 255, 0.479);
}

.aparatura-slider .splide__arrow svg:focus{
  outline: none;
  border: none;
}

.aparatura-slider .splide__arrow svg:hover {
  fill: rgba(255, 255, 255, 0.479);
}

.aparatura-slider .splide__arrows--ttb,
.aparatura-slider .splide__arrow--prev {
  top: 0em;
}

.aparatura-slider .splide__arrows--ttb,
.aparatura-slider .splide__arrow--next {
  bottom: 0em;
  top: auto;
}

.aparatura-slider .card-image {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (max-width: 768px) {
  .aparatura-slider .splide{
    padding: 3em 0;
  }
  
}

