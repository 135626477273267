:root {
  --back_primary: #22272b;
  --sidebar_primary: #16191c;
  --white: #fff;
  --input_background: #3d3d3d2d;
}

/* Sidebar */

.main .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background-color: var(--sidebar_primary);
  z-index: 999;
  padding: 0.4rem 0.8rem;
  transition: all 0.3s ease;
}

.main .sidebar #menu_btn {
  position: absolute;
  top: 0.4rem;
  left: 50%;
  font-size: 1.2rem;
  line-height: 50px;
  transform: translateX(-50%);
  cursor: pointer;
  color: var(--white);
}

.main .sidebar.active #menu_btn {
  left: 90%;
}

.main .sidebar.active {
  width: 250px;
}

.main .sidebar .top .logo img {
  display: flex;
  height: 2.5rem;
  width: fit-content;
  align-items: center;
  opacity: 0;
}

.main .sidebar.active .top .logo img {
  opacity: 1;
  width: 4rem;
  margin-top: 0.5rem;
}

.main.sidebar ~ .main-content {
  left: 250px;
  width: calc(100% - 250px);
}

.main .sidebar .top .user p {
  color: var(--light);
  opacity: 1;
  margin-left: 1rem;
}

.main .sidebar .top .user {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  font-weight: 600;
}

.main .sidebar p {
  opacity: 0;
}

.main .sidebar.active p {
  opacity: 1;
}

.main .sidebar ul li {
  position: relative;
  list-style-type: none;
  height: 50px;
  width: 90%;
  margin: 0.4rem 0rem;
  line-height: 50px;
  margin-left: 0.2rem;
}

.main .sidebar ul {
  margin-top: 1.5rem;
}

.main .sidebar ul li a {
  display: flex;
  text-decoration: none;
  border-radius: 0.8rem;
  height: 3.1rem;
  justify-content: center;
}

.main .sidebar.active ul li a {
  justify-content: unset !important;
}

.main .sidebar ul li a:hover {
  background-color: var(--light);
  color: var(--white);
}

.main .sidebar ul li:last-child a:hover {
  background-color: unset;
  color: unset;
}

.main #lastchild {
  position: absolute;
  bottom: 9;
  display: flex;
}

.main .sidebar ul li a i {
  min-width: 50px;
  text-align: center;
  height: 50px;
  border-radius: 12px;
  line-height: 50px;
  font-size: 20;
  color: var(--white);
}

.main .sidebar .nav_item {
  opacity: 0;
  display: none;
}

.main .sidebar.active .nav_item {
  opacity: 1;
  font-weight: 600;
  font-size: 14;
  display: unset !important;
  color: var(--white);
}

.main .main_content {
  position: relative;
  min-height: 100vh;
  top: 0;
  left: 80px;
  transition: all 0.5s ease;
  width: calc(100% - 80px);
  padding: 1rem;
}

/* table */

.table-bordered td,
.table-bordered th {
  border: 1px solid #53565a !important;
}

/* userdatabase */

.section_userdatabase,
.section_adduser {
  margin-top: 6rem;
}

/* input */

.adduser_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.adduser_form input {
  height: 40px;
  background-color: var(--input_background);
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  margin-bottom: 1.5rem;
  width: 100%;
}

.adduser_form label {
  text-transform: uppercase;
  font-size: 12px;
}

.adduser_form input::placeholder {
  color: var(--back_primary);
  font-weight: 600;
}

.adduser_form input:focus {
  outline: none;
}
