.nav-page .navCard {
  position: relative;
  width: 100%;
  background-color: rgb(0, 0, 0) !important;
  padding: 2rem;
  margin: 0.5rem 0;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.048);
  transition: transform 0.2s ease;
  height: 6rem;
  border: 0.25px solid rgba(20, 20, 20, 0.815);
}

.nav-page .navCard:hover {
  transform: translateY(-5px);
}

@media only screen and (max-width: 768px) {
  .nav-page .navcard-container {
    padding: 1rem !important;
  }

  .nav-page .navCard {
    border: 0.25px solid rgb(31, 31, 31) !important;
  }
}

.nav-page .navCard .logo_navCard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: invert(0.3), opacity(0.01rem);
}

.nav-page .navCard img {
  height: auto;
  width: 20%;
  filter: brightness(0.2);
  position: relative;
  z-index: 0;
}

.nav-page .navCard h1 {
  color: var(--light);
  text-transform: uppercase;
  z-index: 1;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 992px) {
  .nav-page .navCard h1 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .nav-page .navCard img {
    width: 15% !important;
  }

  .nav-page .navCard h1 {
    font-size: 0.8rem !important;
  }

  .nav-page .aboutUsCard p {
    line-height: 1.4rem !important;
    max-width: 100% !important;
  }
  .nav-page .aboutUsCard h1 {
    font-size: 1.8em !important;
  }
}

.nav-page .aboutUsCard h1 {
  font-size: 2.5em;
  font-weight: 600;
  color: var(--light);
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding-bottom: 6px;
}

.nav-page .aboutUsCard p {
  color: var(--light);
  font-size: 18px;
  padding-bottom: 2rem;
  line-height: 1.7rem;
  font-weight: 200;
  max-width: 90%;
}

.nav-page .image-card img {
  border-radius: 4px;
  width: 100%;
  filter: brightness(0.9);
}
