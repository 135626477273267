.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 8%;
  background: rgba(25, 24, 24, 0.11);
  backdrop-filter: blur(1rem);
}

.logo img {
  height: 4rem;
  width: auto;
  padding: 0.1rem;
  padding: .3rem;;
  filter: brightness(8.5);
}

.navbar h1 {
  color: var(--light);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}
