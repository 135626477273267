.ourProduction {
  max-width: 100%;
}

.ourProduction .splide__slide {
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  height: 160px;
}

@media only screen and (max-width: 1296px) and (min-width: 1024px) {
  .ourProduction .splide__slide {
    height: 140px;
  }
}

@media only screen and (max-width: 768px) {
  .ourProduction .splide__slide {
    height: 220px;
  }
}

@media only screen and (max-width: 625px) {
  .ourProduction .splide__slide {
    height: 180px;
  }
}

.ourProduction .card-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  filter: brightness(0.5);
}

.ourProduction .card-image:hover {
  transform: scale(1.21);
}
.ourProduction .card-text {
  position: absolute;
  bottom: 20px;
  left: 10px;
  padding: 0 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 0.75rem;
}

.ourProduction .card-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  font-size: 2rem;
}

.splide__pagination {
  display: none !important;
}

.ourProduction .splide {
  overflow: hidden;
  padding: 0 4em;
  cursor: pointer;
}

.ourProduction_page .ourProduction .splide {
  padding: 2rem 4em !important;
}

@media only screen and (max-width: 768px) {
  .ourProduction .splide {
    overflow: hidden;
    padding: 2rem 8em;
  }
}

@media only screen and (max-width: 700px) {
  .ourProduction .splide {
    overflow: hidden;
    padding: 2rem 4em;
  }
}

@media only screen and (max-width: 505px) {
  .ourProduction .splide__slide {
    height: 130px !important;
  }

  .ourProduction .splide {
    padding: 2rem 3em !important;
  }
}

.ourProduction .splide__arrow svg {
  fill: var(--light) !important;
}

.ourProduction img {
  height: 3rem;
  width: auto;
  filter: invert(1);
}

/* react modal */

.ReactModal__Content {
  border: none !important;
  background-color: unset !important;
}

.ReactModal__Overlay {
  z-index: 10 !important;
}
